import t from 'prop-types'

export const RenderHTML = ({ content }) => (
  <div
    data-testid="renderhtml"
    dangerouslySetInnerHTML={{ __html: content || '' }}
  />
)

RenderHTML.propTypes = {
  content: t.string,
}

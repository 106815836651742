import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useIsMobile,
} from '@grupoboticario/flora-react'
import { Layout } from 'components/Layout'
import { useStore } from 'utils/theme'

export const NotFound = () => {
  const isMobile = useIsMobile()
  const store = useStore()

  return (
    <Layout
      size="large"
      css={{
        padding: '$4',
        '@desktop': {
          padding: 0,
        },
      }}
    >
      <Flex
        data-testid="NotFound"
        direction="column"
        align="center"
        justify="center"
        css={{ height: '100vh' }}
      >
        <Warning />
        <Heading as="h1" level={isMobile ? 4 : 3} css={{ marginBottom: '$6' }}>
          Página não encontrada
        </Heading>

        <Text
          color="$nonInteractiveAuxiliar"
          css={{ marginBottom: '$12', fontSize: 20 }}
        >
          Ops, a página que está procurando não existe.
        </Text>
        <Button
          as="a"
          href={store.domain}
          isFull={isMobile}
          target="_blank"
          rel="noopener"
          rightIcon={<Arrow />}
          css={{ paddingInline: '$8' }}
        >
          Ir para o site
        </Button>
      </Flex>
    </Layout>
  )
}

const Arrow = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.64645 11.3536C3.45118 11.1583 3.45118 10.8417 3.64645 10.6465L10.2929 4L6 4C5.72386 4 5.5 3.77614 5.5 3.5C5.5 3.22386 5.72386 3 6 3L11.5 3C11.6326 3 11.7598 3.05268 11.8536 3.14645C11.9473 3.24022 12 3.36739 12 3.5L12 9.00001C12 9.27615 11.7761 9.50001 11.5 9.50001C11.2239 9.50001 11 9.27615 11 9.00001V4.70711L4.35355 11.3536C4.15829 11.5488 3.84171 11.5488 3.64645 11.3536Z"
      fill="currentColor"
    ></path>
  </svg>
)

const Warning = () => (
  <Flex
    align="center"
    justify="center"
    css={{
      size: 70,
      borderRadius: '50%',
      backgroundColor: '#FEF0C7',
      border: '10px solid #FFFAEB',
      marginBottom: '$6',
    }}
  >
    <Box
      as="svg"
      width="25"
      height="25"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.4449 0.608765C8.0183 -0.107015 6.9817 -0.107015 6.55509 0.608766L0.161178 11.3368C-0.275824 12.07 0.252503 13 1.10608 13H13.8939C14.7475 13 15.2758 12.07 14.8388 11.3368L8.4449 0.608765ZM7.4141 1.12073C7.45288 1.05566 7.54712 1.05566 7.5859 1.12073L13.9798 11.8488C14.0196 11.9154 13.9715 12 13.8939 12H1.10608C1.02849 12 0.980454 11.9154 1.02018 11.8488L7.4141 1.12073ZM6.8269 4.48611C6.81221 4.10423 7.11783 3.78663 7.5 3.78663C7.88217 3.78663 8.18778 4.10423 8.1731 4.48612L8.01921 8.48701C8.00848 8.766 7.7792 8.98664 7.5 8.98664C7.2208 8.98664 6.99151 8.766 6.98078 8.48701L6.8269 4.48611ZM8.24989 10.476C8.24989 10.8902 7.9141 11.226 7.49989 11.226C7.08567 11.226 6.74989 10.8902 6.74989 10.476C6.74989 10.0618 7.08567 9.72599 7.49989 9.72599C7.9141 9.72599 8.24989 10.0618 8.24989 10.476Z"
        fill="#DC6803"
      ></path>
    </Box>
  </Flex>
)

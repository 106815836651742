import { Container } from '@grupoboticario/flora-react'

export const Layout = (props) => (
  <Container
    as="main"
    data-testid="layout"
    css={{
      width: '100%',
      padding: 0,
    }}
    {...props}
  />
)

import { Image } from '@grupoboticario/flora-react'
import t from 'prop-types'
import { getProductImage } from 'utils/image'

export const ProductImage = ({ product, size = '300' }) => {
  if (!product.imageObjects?.length) return null
  return (
    <Image
      src={getProductImage(product?.imageObjects, `w_${size},dpr_2.0`)}
      size={size}
      alt={product.imageObjects?.[0]?.alternateText || product.name}
    />
  )
}

ProductImage.propTypes = {
  product: t.object,
  size: t.oneOfType([t.string, t.number]),
}

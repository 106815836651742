import { Flex, Image } from '@grupoboticario/flora-react'
import t from 'prop-types'
import { buOrgId, getLogo } from 'utils/image'

export const Header = ({ businessUnit }) => {
  const logoSrc =
    businessUnit === 'GB' ? '/logo.svg' : getLogo(buOrgId[businessUnit])
  const width = businessUnit === 'GB' ? '180' : '120'
  return (
    <Flex justify="center" css={{ padding: '$4' }} data-testid="Header">
      <div>
        <Image src={logoSrc} alt="Logo da marca" width={width} />
      </div>
    </Flex>
  )
}

Header.propTypes = {
  businessUnit: t.string,
}

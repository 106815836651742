import { Flex, Spinner } from '@grupoboticario/flora-react'

export const Loading = () => (
  <Flex
    align="center"
    justify="center"
    css={{
      position: 'fixed',
      width: '100%',
      height: '100%',
      left: 0,
      right: 0,
    }}
  >
    <Spinner
      color="$backgroundAltPrimary"
      size="xl"
      speed="450ms"
      thickness="3px"
      data-testid="loading"
    />
  </Flex>
)

import { Box } from '@grupoboticario/flora-react'
import t from 'prop-types'

export const Footer = ({ backgroundColor }) => (
  <Box
    as="footer"
    data-testid="Footer"
    css={{
      backgroundColor: backgroundColor,
      color: '$backgroundPrimary',
      padding: '$7',
      '@desktop': { paddingBlock: '$20', paddingInline: 100 },
    }}
  />
)
Footer.propTypes = {
  backgroundColor: t.string,
}

import { FloraProvider, globalCss } from '@grupoboticario/flora-react'
import { BrowserRouter } from 'react-router-dom'
import { Router } from 'routes'
import { useStore } from 'utils/theme'

const globalStyle = globalCss({
  'html, body, body > div:first-child, div#__next, div#__next > div': {
    height: '100%',
  },
})

const App = () => {
  globalStyle()

  const store = useStore()

  return (
    <div data-testid="app">
      <FloraProvider theme={store.theme}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </FloraProvider>
    </div>
  )
}

export default App

import { Box, Heading } from '@grupoboticario/flora-react'
import t from 'prop-types'

export const Intro = ({ product, backgroundColor, color }) => {
  return (
    <Box
      css={{
        background: backgroundColor,
        padding: '$7',
        marginTop: '$2',
        borderBottom: '8px solid $backgroundSecondary',
        '@desktop': {
          padding: '$20 0 $20 100px',
          marginTop: 0,
        },
      }}
    >
      <Box
        css={{
          '@desktop': {
            maxWidth: 700,
          },
        }}
      >
        <Heading
          as="h1"
          level="5"
          color={color}
          css={{
            fontWeight: '500',
            marginBottom: '$6',
            '@desktop': {
              marginBottom: '$12',
            },
          }}
        >
          {product.name}
        </Heading>
      </Box>
    </Box>
  )
}

Intro.propTypes = {
  product: t.object,
  backgroundColor: t.string,
  color: t.string,
}

export const getProductImage = (image, transformation) => {
  const betterImage = image[0].extraLarge || image[0].large
  return betterImage.replace(/f_auto/, `f_auto,${transformation}`)
}

export const getLogo = (id = 47) => {
  const path = `https://res.cloudinary.com/beleza-na-web/image/upload/f_svg,fl_progressive,q_auto:eco,w_120/v1/blz/assets-store/0.0.348/images/store/${id}/logo`
  return +id === 46 ? path.replace('f_svg', 'f_auto') : path
}

export const buOrgId = {
  BLZ: 1,
  VULT: 39,
  BBX: 44,
  QDB: 45,
  EUD: 46,
  BOT: 47,
  OUI: 60,
}

import {
  beautyboxTheme,
  belezanawebTheme,
  boticarioTheme,
  eudoraTheme,
  gbInstitucionalTheme,
  ouiTheme,
  vultTheme,
  quemDisseBereniceTheme,
  drJonesTheme,
  trussTheme,
} from '@grupoboticario/flora'

const domains = {
  beautybox: {
    domain: 'https://www.beautybox.com.br',
    theme: beautyboxTheme,
    businessUnit: 'BBX',
  },
  belezanaweb: {
    domain: 'https://www.belezanaweb.com.br',
    theme: belezanawebTheme,
    businessUnit: 'BLZ',
  },
  grupoboticario: {
    domain: 'https://www.grupoboticario.com.br',
    theme: gbInstitucionalTheme,
    businessUnit: 'GB',
  },
  boticario: {
    domain: 'https://www.boticario.com.br',
    theme: boticarioTheme,
    businessUnit: 'BOT',
  },
  eudora: {
    domain: 'https://www.eudora.com.br',
    theme: eudoraTheme,
    businessUnit: 'EUD',
  },
  ouiparis: {
    domain: 'https://www.ouiparis.com',
    theme: ouiTheme,
    businessUnit: 'OUI',
  },
  quemdisseberenice: {
    domain: 'https://www.quemdisseberenice.com.br',
    theme: quemDisseBereniceTheme,
    businessUnit: 'QDB',
  },
  vult: {
    domain: 'https://www.vult.com.br',
    theme: vultTheme,
    businessUnit: 'VULT',
  },
  drjones: {
    domain: 'https://www.drjones.com.br',
    theme: drJonesTheme,
    businessUnit: 'BLZ',
  },
  trussprofessional: {
    domain: 'https://www.trussprofessional.com.br',
    theme: trussTheme,
    businessUnit: 'BLZ',
  },
  default: {
    domain: 'https://www.boticario.com.br',
    theme: boticarioTheme,
    businessUnit: 'BOT',
  },
}

export const getThemeByHostname = (hostname) => {
  const [, matched] =
    Object.entries(domains).find(([bu]) => RegExp(bu).test(hostname)) || []

  return matched || domains.default
}

export const useStore = (hostname) =>
  getThemeByHostname(hostname || window.location.hostname)

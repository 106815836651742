import {
  Box,
  Flex,
  useIsMobile,
  Text,
  Card,
  Image,
  Spinner,
} from '@grupoboticario/flora-react'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { Layout } from 'components/Layout'
import { Loading } from 'components/Loading'
import { NotFound } from 'components/NotFound'
import { useParams } from 'react-router-dom'
import { useProduct } from 'utils/useRequest'

import { useStore } from 'utils/theme'
import { Description } from './Description/Description'
import { Intro } from './Intro/Intro'
import { ProductImage } from './ProductImage/ProductImage'

export const Product = () => {
  const { sku, bu } = useParams()

  const businessUnitUppercase = bu?.toUpperCase()

  const isMobile = useIsMobile()

  const store = useStore()

  const { product, notFound, isLoading } = useProduct({
    businessUnit: bu ? businessUnitUppercase : store.businessUnit,
    sku,
  })

  if (isLoading) {
    return <Loading />
  }

  if (notFound) {
    return <NotFound />
  }
  const handleCardClick = (sku) => {
    const url = `https://rotulo-digital.grupoboticario.com.br/${sku}`
    window.open(url, '_blank')
  }

  return product?.total ? (
    <Box
      size="large"
      css={{
        padding: '$4',
        minHeight: '100vh',
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: '#F5F1EB',
        '@desktop': {
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        },
      }}
    >
      <Box css={{ marginBottom: '$10' }}>
        <Image src="/logo.svg" css={{ width: '400px', height: '100px' }} />
      </Box>
      <Flex
        direction="column"
        align="center"
        css={{
          '@desktop': {
            textAlign: 'center',
          },
        }}
      >
        <Text
          css={{
            fontSize: '1.5em',
            marginBottom: '$4',
          }}
          color="#fff"
        >
          {product.items[0] ? (
            'Para mais informações, selecione um dos produtos'
          ) : (
            <Spinner
              color="#fff"
              label="Loading, please wait."
              size="sm"
              speed="800ms"
              thickness="2px"
            />
          )}
        </Text>

        <Flex
          data-testid="NotFound"
          css={{
            flexDirection: 'column',
            alignItems: 'center',
            gap: '$4',
            '@desktop': {
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              justifyContent: 'center',
              maxWidth: '1280px',
            },
          }}
        >
          {product?.items.map((item, index) => (
            <Flex
              key={index}
              direction="column"
              align="center"
              justify="center"
              css={{ width: '100%', '@desktop': { width: '500px' } }}
            >
              <Card
                css={{
                  width: '100%',
                  transition: 'transform 0.3s',
                  flexDirection: 'column',
                  minHeight: '300px',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
                padded
                variant="interactive"
                onClick={() => handleCardClick(item?.sku)}
              >
                <Image
                  alt="Imagem do produto"
                  src={item?.imageObjects[0]?.small || ''}
                  css={{
                    objectFit: 'cover',
                  }}
                />
                <Box css={{ padding: '$3 0' }}>
                  <Header businessUnit={item?.businessUnit} />
                  <Text
                    as="h4"
                    css={{ marginBlock: '$1' }}
                    isTruncated
                    size="bodyLargeShort"
                    weight="heavy"
                  >
                    {item.name}
                  </Text>
                  <Box>{item.departments[0]?.name || ''}</Box>
                </Box>
              </Card>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Box>
  ) : (
    <Layout>
      <Flex
        direction="column"
        css={{
          '@desktop': {
            display: 'grid',
            gridTemplateColumns: 'minmax(600px, 1fr) 2fr',
            height: '100vh',
          },
        }}
      >
        <Box
          css={{
            '@desktop': {
              position: 'sticky',
              top: 0,
              alignSelf: 'flex-start',
            },
          }}
        >
          {bu ? (
            <Header businessUnit={bu ? 'GB' : product.businessUnit} />
          ) : (
            <Header businessUnit={product.businessUnit} />
          )}
          <Flex justify="center">
            <ProductImage product={product} size={isMobile ? '300' : '600'} />
          </Flex>
        </Box>
        <Box
          css={{
            '@desktop': {
              borderLeft: '8px solid $backgroundSecondary',
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
            },
          }}
        >
          <Intro
            product={product}
            backgroundColor={bu ? '#F5F1EB' : '$backgroundAltPrimary'}
            color={bu ? '#011E38' : '$backgroundPrimary'}
          />
          {product.details && (
            <Description
              product={product}
              color={bu ? '#011E38' : '$backgroundPrimary'}
            />
          )}
          <Footer backgroundColor={bu ? '#F5F1EB' : '$backgroundAltPrimary'} />
        </Box>
      </Flex>
    </Layout>
  )
}

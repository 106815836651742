import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionTrigger,
  Box,
  Heading,
  Text,
} from '@grupoboticario/flora-react'
import { RenderHTML } from 'components/RenderHTML'
import t from 'prop-types'

const DescriptionItem = ({ title, content, children }) => (
  <Accordion type="multiple" defaultValue="ingredientes">
    <AccordionItem value={title.toLowerCase().replace(/\s+/g, '-')}>
      <AccordionTrigger>
        <Text size="bodyLargeShort" css={{ fontWeight: 500 }}>
          {title}
        </Text>
      </AccordionTrigger>
      <AccordionPanel>
        {content && <RenderHTML content={content} />}
        {!content && children}
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
)

DescriptionItem.propTypes = {
  title: t.string,
  content: t.string,
  children: t.node,
}

const pyramidKeys = {
  topNodes: 'Topo',
  middleNodes: 'Corpo',
  baseNodes: 'Fundo',
}
const OlfactivePyramid = ({ olfactivePyramid }) =>
  Object.entries(olfactivePyramid).map(([key, value]) => (
    <Text as="p" key={key} size="bodyLargeStandard">
      <strong>{pyramidKeys[key]}:</strong>{' '}
      {value.at(-1) ? value + '.' : value + ','}
    </Text>
  ))

export const Description = ({ product, color }) => (
  <Box
    css={{
      padding: '$7',
      borderBottom: '8px solid $backgroundSecondary',
      '@desktop': {
        paddingLeft: '100px',
        maxWidth: 700,
        flex: 1,
      },
    }}
    data-testid="description"
  >
    <Heading
      as="h3"
      level="5"
      color={color}
      css={{ fontWeight: '500', marginBottom: '$6' }}
    >
      Sobre o produto
    </Heading>

    {!product.details && (
      <Text data-testid="no-description">Não há descrição</Text>
    )}
    {product.details && (
      <>
        {product.details.description && (
          <DescriptionItem
            title="Descrição"
            content={product.details.description}
          />
        )}

        {product.details.advice && (
          <DescriptionItem title="Como usar" content={product.details.advice} />
        )}

        {product.details.ingredients && (
          <DescriptionItem
            title="Ingredientes"
            content={product.details.ingredients}
          />
        )}

        {Object.entries(product.details.olfactivePyramid || {}).some(
          ([, value]) => !!value,
        ) && (
          <DescriptionItem title="Pirâmide Olfativa">
            <OlfactivePyramid
              olfactivePyramid={product.details.olfactivePyramid}
            />
          </DescriptionItem>
        )}

        {product.details.action && (
          <DescriptionItem title="Ação" content={product.details.action} />
        )}

        {product.details.result && (
          <DescriptionItem
            title="Resultados"
            content={product.details.result}
          />
        )}
      </>
    )}
  </Box>
)

Description.propTypes = {
  product: t.object,
  backgroundColor: t.string,
  color: t.string,
}

Description.defaultProps = {
  product: {},
}

import useSWR from 'swr'

const fetcher = (url, businessUnit, config = {}) => {
  config.headers = {
    ...config.headers,
    client_id: process.env.REACT_APP_APIGEE_CLIENT_ID,
    client_secret: process.env.REACT_APP_APIGEE_CLIENT_SECRET,
  }
  if (businessUnit !== 'GB') {
    return fetch(`${process.env.REACT_APP_GB_PRODUCT_URL}/${url}`, config).then(
      (response) =>
        response.json().then((data) => {
          if (!response.ok) {
            throw Error(data.error || 'HTTP error')
          }
          return data
        }),
    )
  } else if (businessUnit === 'GB') {
    return fetch(`${process.env.REACT_APP_GB_PRODUCT_URL}=${url}`, config).then(
      (response) =>
        response.json().then((data) => {
          if (!response.ok) {
            throw Error(data.error || 'HTTP error')
          }
          return data
        }),
    )
  }
}

export const useRequest = (url, config, businessUnit) =>
  useSWR(url, () => fetcher(url, businessUnit, config), {
    revalidateOnFocus: false,
    refreshInterval: 0,
  })

export const useProduct = ({ sku, businessUnit }) => {
  const config = { headers: { 'x-businessUnit': businessUnit } }

  const { data, error, isValidating, ...rest } = useRequest(
    sku ? `${sku}` : '',
    config,
    businessUnit,
  )

  return {
    product: data,
    notFound: error || (!isValidating && !data),
    isLoading: (!error && !data) || isValidating,
    error,
    ...rest,
  }
}
